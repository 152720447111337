
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { UiMixin } from '@/mixins/ui-mixin';
import { sleep } from '@/config/lib';

@Component({})
export default class Profile extends Mixins<UiMixin>(UiMixin) {
  title = '';
  user: any = {};
  form: any = {
    password: '',
    pass: '',
  };

  async changePassword() {
    this.setLoader(true);
    try {
      await this.$http.patch(`/auth/change-password`, {
        password: this.form.password,
      });
      this.alert('Contraseña actualizada correctamente', 'success');
    } catch (e) {
      this.alert('Error guardando contraseña', 'error');
    }
    this.form.password = '';
    this.form.pass = '';
    this.setLoader(false);
  }

  async getInfoUser() {
    const req: any = await this.$http.get(`/auth/user`);
    this.title = `${req.data.user.name} ${req.data.user.lastname}`;
    this.user = req.data.user;
  }

  async mounted() {
    this.setLoader(true);
    await this.getInfoUser();
    this.setLoader(false);
  }
}
